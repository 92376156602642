import { createRouter, createWebHistory } from 'vue-router';

import App from "../App.vue";

const routes = [
  { path: "/", component: App },
];

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
