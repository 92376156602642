<template>
    <div>
        <ul class="event-list">
            <li class = "event-list-item border-bottom"
                :class="{ active: event.id == this.activeid }" 
                v-for="event in events" 
                :key="event.id" 
                @click="this.$emit('EventListSelected', event);this.activeid=event.id"
            >
                <div :class="{ past: new Date(event.date) < Date.now() }">
                    {{ getDate(event) }} 
                </div> 
                <h5>
                    <b>{{ event.name }}</b> - {{ event.city }}
                </h5>
            </li>
        </ul>
    </div>
</template>

<script>

export default ({
    name: 'EventList',
    props: {
        events: {
            type: Array,
            required: true
        },
    },
    data () {
        return {
            activeid : 0,
        }
    },
    methods: {
        getDate (event) {
            var d = new Date(event.date)
            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
            return d.toLocaleDateString('de-DE', options)
        }
    }
})
</script>

<style language="scss" scoped>
.event-list {
    list-style-type: none;
    padding: 0;
}
.event-list-item {
    padding: 1em;
    border-left: 3px solid transparent;
}
.event-list-item:hover {
    background-image: linear-gradient(to right, silver , gainsboro);
    transition: background-color 0.5s ease;
}
.past {
    color: darkred;
}
.active {
    background-image: linear-gradient(to right, silver , gainsboro);
    transition: background-color 0.5s ease;
    border-left: 3px solid black;
}
</style>