<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">

                <!-- Header of the modal -->
                <div class="modal-header flex-column">
                    <slot name="header">
                        <h3>Veranstaltung bearbeiten</h3>
                    </slot>
                    <div v-if="error" class="alert alert-danger w-100" role="alert">{{ error }}</div>
                </div>

                <!-- Body of the modal -->
                <div class="modal-body">   
                    <div class="row">

                        <!-- Right section for event master data -->
                        <div class="col-8">
                            <slot name="body">
                                <h4>Stammdaten</h4>
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input class="form-control" type="text" v-model="name" name="name" />
                                </div>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label for="program">Programm</label>
                                        <input class="form-control" type="text" v-model="program" name="program" />
                                    </div>
                                    <div class="col-6">
                                        <label for="location">Location</label>
                                        <input class="form-control" type="text" v-model="location" name="location" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label for="date">Datum</label>
                                        <input class="form-control" type="date" v-model="date" name="date" />
                                    </div>
                                    <div class="col-6">
                                        <label for="starttime">Startzeit</label>
                                        <input class="form-control" type="text" v-model="starttime" name="starttime" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-2">
                                        <label for="zipcode">PLZ</label>
                                        <input class="form-control" type="text" v-model="zipcode" name="zipcode" />
                                    </div>
                                    <div class="col-4">
                                        <label for="city">Stadt</label>
                                        <input class="form-control" type="text" v-model="city" name="city" />
                                    </div>
                                    <div class="col-6">
                                        <label for="street">Straße</label>
                                        <input class="form-control" type="text" v-model="street" name="street" />
                                    </div>
                                </div>
                            </slot>
                        </div>

                        <!-- Right section for price categories -->
                        <div class="col-4 border-left">
                            <h4>Preiskategorien</h4>
                            <div class="row">
                                <div class="col-5">
                                    PK-Name
                                </div>
                                <div class="col-5 text-right">
                                    Preis €
                                </div>
                            </div>
                            <div class="row pt-1 pb-1"
                                v-for="(category) in pricecategories" 
                                :key="category.id"
                            >
                                <div class="col-5 my-auto">
                                    {{ category.name}}
                                </div>
                                <div class="col-5 my-auto text-right">
                                    {{ category.price.toFixed(2) }}€
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <slot name="fileupload">
                        <h4>Neues Ticket Template</h4>
                        <div v-if="fileerror" class="alert alert-danger w-100" role="alert">{{ fileerror }}</div>
                        <div class="modal-upload flex-column align-items-center" @drop="dragFile" @dragover.prevent @drop.prevent>
                            <div v-if="file == null">
                                <div class="text-center"><input type="file" ref="inputFile" @change="uploadFile"/></div>
                                <div class="dragndrop">Drag and Drop hier</div>
                            </div>
                            <div v-else style="padding:25px;">
                                {{ file.name }} <i class="fas fa-trash-alt" @click="this.file = null;this.error=''"></i>
                            </div>
                        </div>
                    </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <!-- Cancel button, emits modalClosed -->
                        <button class="btn btn-outline-danger" @click="$emit('EventModalClosed')">Abbrechen</button>
                        <!-- Save button, calls addEvent method -->
                        <button class="btn btn-success" @click="updateEvent" >Aktualisieren</button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
export default {
    name: "UpdateEventModal",
    props: {
        event: Object
    },
    setup (props) {
        const id = ref(props.event.id)
        const date = ref(props.event.date.substring(0,10))
        const name = ref(props.event.name)
        const program = ref(props.event.program)
        const location = ref(props.event.location)
        const starttime = ref(props.event.starttime)
        const entrytime = ref(props.event.entrytime)
        const zipcode = ref(props.event.zipcode)
        const city = ref(props.event.city)
        const street = ref(props.event.street)

        const pricecategories = ref(props.event.pricecategories)

        return {
            id,
            date,
            name,
            program,
            location,
            starttime,
            entrytime,
            zipcode,
            city,
            street,
            pricecategories
        }
    },
    data () {
        return {
            categoryname: "",
            price: "0.00",
            categories: [],
            file: null,
            error: "",
            fileerror: "",
        }
    },
    methods: {
        updateEvent () {
            this.error = ""

            var e = {
                name: this.name,
                program: this.program,
                location: this.location,
                zipcode: this.zipcode,
                city: this.city,
                street: this.street,
                starttime: this.starttime,
                entrytime: this.entrytime,
                date: this.date + 'T00:00:00.000Z',
                pricecategories: this.pricecategories,
            }
            var formData = new FormData()

            formData.append("template", this.file);
            formData.append("data", JSON.stringify(e));
            axios.put(process.env.VUE_APP_BACKEND + '/api/events/' + this.id, formData,{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                this.$emit('EventModalUpdated')
            }).catch((e) => {
                if (e.response.status === 401) {
                    this.$emit('logout')
                } else {
                    this.error = e.response.data.message
                }
            })           
        },
        dragFile(e) {
            this.setFile(e.dataTransfer.files)
        },
        uploadFile(e) {
            this.setFile(e.target.files)
        },
        setFile(files) {
            if (files.length > 1) {
                this.fileerror = "Es kann nur eine Datei als Ticket Template hochgeladen werden"
                this.$refs.inputFile.value = null
            } else if (files[0].type != "application/pdf") {
                this.fileerror = "Es können nur PDF Dateien als Ticket Template hochgeladen werden"
                this.$refs.inputFile.value = null
            } else if (files[0].size > 3000000) {
                this.fileerror = "Die Datei darf maximal 3mb groß sein"
                this.$refs.inputFile.value = null
            } else {
                    this.fileerror = ""
                    this.file = files[0]
            }
        },
        romanize (num) {
            if (isNaN(num))
                return NaN;
            var digits = String(+num).split(""),
                key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                    "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                    "","I","II","III","IV","V","VI","VII","VIII","IX"],
                roman = "",
                i = 3;
            while (i--)
                roman = (key[+digits.pop() + (i * 10)] || "") + roman;
            return Array(+digits.join("") + 1).join("M") + roman;
        },
    },
    computed: {
        valid() {
            if (this.name.value && this.date.value) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>

<style lang="scss" scoped>

label {
    margin: 0px;
}

.modal-header {
    padding: 0px;
}

.modal-body {
    padding:0px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  margin: 0px auto;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-upload {
    padding: 5px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
}

.dragndrop {
    text-align: center;
    margin: 10px;
    color: #a3a3a3;
}

.fa-trash-alt:hover {
    color: #a3a3a3;
}
</style>