<template>
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header flex-column">
            <slot name="header">
              <h3>Login</h3>
            </slot>
            <div v-if="error" class="alert alert-danger w-100" role="alert">{{ error }}</div>
          </div>

          <div class="modal-body">
            <slot name="body">
                <div class="form-group">
                    <label for="name">Benutzername</label>
                    <input class="form-control" v-on:keyup.enter="$emit('login', this.username, this.password)" type="text" v-model="username" name="username" />
                </div>
                <div class="form-group">
                    <label for="name">Passwort</label>
                    <input class="form-control" v-on:keyup.enter="$emit('login', this.username, this.password)" type="password" v-model="password" name="password" />
                </div>
            </slot>
          </div>

        <div class="modal-footer">
            <slot name="footer">
                <button class="btn btn-success" @click="$emit('login', this.username, this.password)">Login</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "TheLoginModal",
    props: {
      error: String
    },
    data () {
        return {
            username: "",
            password: "",
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}
</style>