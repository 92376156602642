<template>
    <div>
        <div class="input-group">
          <input class="form-control m-2 w-75" type="text" placeholder="Bestellnummer suchen..." v-model="searchorder">
          <button type="button" class="btn bg-transparent" @click="searchorder = ''" style="margin-left: -40px; z-index: 100;">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="ticket-search-info">
            <span v-if="searchorder != ''">
                Suchergebnisse für Bestellung {{ searchorder }} 
                <a v-if="searchorder != '' && filteredListOrder.length > 0" :href="backend + '/api/orders/' + this.tickets[0].eventid + '/' + searchorder + '/print'"> - Bestellung herunterladen</a>
            </span>
        </div>
        <ul class="ticket-list">
            <li class = "ticket-list-item" v-for="ticket in filteredListOrder" :key="ticket.id">
                <div class="d-flex justify-content-between">
                    <div class="flex-item">                      
                        <b>Bestellung {{ ticket.order}}</b>
                        <span> - Ticket {{ ticket.code }}</span>
                    </div>
                    <div class="flex-item">
                        <a :href="backend + '/api/tickets/' + ticket.id + '/print'"><i class="fas fa-download ticket-button"></i></a>
                        <a href="javascript:;" v-on:click="deleteTicket(ticket.id)"><i class="fas fa-trash-alt ticket-button"></i></a>
                    </div>
                </div>
            </li>
        </ul>
        <div class="d-flex justify-content-center" v-if="!tickets.length">Keine Tickets gefunden</div>
    </div>
</template>

<script>
import axios from 'axios';
export default ({
    name: 'TicketList',
    data() {
        return {
            backend: process.env.VUE_APP_BACKEND,
            searchorder: '',
        }
    },
    props: {
        tickets: {
            type: Array,
            required: true
        },
    },
    methods: {
        deleteTicket(id) {
            if(confirm("Ticket wirklich löschen?")){
                axios.delete(process.env.VUE_APP_BACKEND + '/api/tickets/' + id)
                .then(response => { this.$emit('TicketDeleted')  })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout()
                    } else {
                        console.log(error);
                    }           
                });
            }
        }
    },
    computed: {
        filteredListOrder () {
            return this.tickets.filter(ticket => ticket.order == this.searchorder || this.searchorder == '')
        }
    }
})
</script>

<style language="scss" scoped>
.ticket-list {
    list-style-type: none;
    padding: 5px;
}
/*.ticket-list-item {
    padding: 1em;
    border-radius: .5em;
    background-color: white;
    margin: .5em;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}*/
.ticket-list-item {
    padding: 1em;
    border-bottom: 1px solid #0000002b;
}
.ticket-list-item i {
    font-size: 1.5em;
}

.ticket-button {
    padding-right: 1em;
    color: grey;
}

.ticket-button:hover {
    color: darkgray;
}

.edit-button {
    padding-right: 1em;
    color: grey;
}

.edit-button:hover {
    color: darkgray;
}

.ticket-search-info {
    font-size: 10px;
    color: grey;
    height: 10px;
    margin: 8px;
}
</style>