<template>
  <div class="row h-100 mh-100 overflow-auto" >
    <div class="col-3 d-flex flex-column h-100 mh-100 m-0 p-0 border-right">
      <div class="d-flex flex-column justify-content-center align-items-center mt-5 pt-3 border-bottom" >
        <button type="button" class="btn btn-outline-success m-2 w-75" id="eventModalButton" @click="openEventModal">Erstellen</button>
        <div class="input-group">
          <input class="form-control m-2 w-75" type="text" placeholder="Suche..." v-model="search">
          <button type="button" class="btn bg-transparent" @click="search = ''" style="margin-left: -40px; z-index: 100;">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <hr class="dashed">
      </div>  
      <div id="event-list" class="flex-grow-1">
        <EventList :events="filteredList" @EventListSelected="selectEvent" @openTicketModal="openTicketModal"/>
      </div>  
    </div>
    <div v-if="currentEvent.id != null" class="col-9 d-flex flex-column h-100 mh-100 m-0 p-0" >
      <div class="d-flex flex-column mt-5 pt-3 px-3 pb-3 border-bottom">
        <h1>{{ currentEvent.name }} - {{currentEvent.city }}</h1>
        {{ formatDate(currentEvent) }} - {{ currentEvent.location }}
        <p>Anzahl Tickets: {{ tickets.length }}</p>
        <div class="d-flex flex-container">
          <div class="flex-item">
             <button type="button" class="btn btn-outline-success m-2" id="ticketModalButton" @click="openTicketModal">Ticket hinzufügen</button>
          </div>
          <div class="flex-item">
            <button type="button" class="btn btn-outline-primary m-2" id="ticketExportButton" @click="exportCSV">CSV exportieren</button>
          </div>
          <div class="flex-item">
            <button type="button" class="btn btn-outline-secondary m-2" @click="openUpdateEventModal">Bearbeiten</button>
          </div>
        </div>
      </div>
      <div id="ticket-list" class="flex-grow-1">
        <TicketList :event="currentEvent" :tickets="tickets" @TicketDeleted="getTickets(currentEvent.id)"/>
      </div>
    </div>

    <!-- Modals -->
    <!-- Create Event Modal -->
    <transition name="modal">
      <EventModal v-if="showEventModal" @EventModalClosed="closeEventModal" @EventModalSaved="closeEventModal" @logout="logout" />
    </transition>
      <!-- Update Event Modal -->
    <transition name="modal">
      <UpdateEventModal v-if="showUpdateEventModal" :event="currentEvent" @EventModalClosed="closeUpdateEventModal" @EventModalUpdated="closeUpdateEventModal" @logout="logout" />
    </transition>
    <!-- Ticket Modal -->
    <transition name="modal">
      <TicketModal v-if="showTicketModal" :event="currentEvent" @TicketModalClosed="closeTicketModal" @TicketModalSaved="closeTicketModal" @logout="logout"/>
    </transition>
    <!-- Login Modal -->
    <transition name="login">
      <LoginModal v-if="!isLoggedIn" @login="login" :error="errorLoginModal"/>
    </transition>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from 'vue';
import axios from 'axios';
import EventList from './components/EventList.vue';
import TicketList from './components/TicketList.vue';
import EventModal from './components/EventModal.vue';
import UpdateEventModal from './components/UpdateEventModal.vue';
import TicketModal from './components/TicketModal.vue';
import LoginModal from './components/LoginModal.vue';

axios.defaults.withCredentials = true
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default ({
  name: 'App',
  setup(props) {
    const isLoggedIn = ref(true)
    const errorLoginModal = ref("")

    const showEventModal = ref(false)

    const showUpdateEventModal = ref(false)

    const showTicketModal = ref(false)

    const currentEvent = ref({})

    const events = ref([])
    const getEvents = async () => {
      axios.get(process.env.VUE_APP_BACKEND + '/api/events')
      .then(response => { 
        events.value = response.data
        currentEvent.value = []
      })
      .catch(error => {
        if (error.response.status === 401) {
          logout()
        } else {
          console.log(error)
        }
      });
    }

    const selectEvent = async (event) => {
      currentEvent.value = event
      getTickets(event.id)
    }


    const tickets = ref([])
    const getTickets = async (id) => {
      tickets.value = [];
      axios.get(process.env.VUE_APP_BACKEND + '/api/events/' + id + '/tickets')
      .then(response => { tickets.value = response.data; })
      .catch(error => {
        if (error.response.status === 401) {
          logout()
        } else {
          console.log(error);
        }
      });
    }

    const openEventModal = async () => {
      showEventModal.value = true
    }

    const closeEventModal = async () => {
      showEventModal.value = false
      getEvents()
    }

    const openUpdateEventModal = async () => {
      showUpdateEventModal.value = true
    }

    const closeUpdateEventModal = async () => {
      showUpdateEventModal.value = false
      getEvents()
    }

    const openTicketModal = async () => {
      showTicketModal.value = true
    }

    const closeTicketModal = async () => {
      showTicketModal.value = false
      getTickets(currentEvent.value.id)
    }

    const exportCSV = () => {
      var url = process.env.VUE_APP_BACKEND + '/api/events/' + currentEvent.value.id + '/csv'
      window.open(url, '_self').focus()
    }

    const login = async (e, p) => {
      errorLoginModal.value = ""
      axios.post(process.env.VUE_APP_BACKEND + '/login', {
        email: e,
        password: p,
      },{
          headers: {
              'Content-Type': 'application/json'
          }
      }).then((response) => {
          console.log(response)
          isLoggedIn.value = true
          getEvents()
      }).catch((e) => {
          errorLoginModal.value = e.response.data.message
      })
      
    }

    const logout = async () => {
      showEventModal.value = false
      showTicketModal.value = false
      events.value = []
      tickets.value = []
      isLoggedIn.value = false
    }

    //Hilfsfunktion
    function formatDate (event) {
      var d = new Date(event.date)
      var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
      return d.toLocaleDateString('de-DE', options)
    }

    const search = ref('')
    const filteredList = computed(() => {
      return events.value.filter(event => {
        return event.name.toLowerCase().includes(search.value.toLowerCase()) || event.city.toLowerCase().includes(search.value.toLowerCase())
      })
    });

    onMounted(() => {
      getEvents();
    })

    return {
      isLoggedIn,
      errorLoginModal,
      showEventModal,
      showUpdateEventModal,
      showTicketModal,
      search,
      filteredList,
      currentEvent,
      events,
      tickets,
      selectEvent,
      openEventModal,
      closeEventModal,
      openUpdateEventModal,
      closeUpdateEventModal,
      openTicketModal,
      closeTicketModal,
      exportCSV,
      login,
      logout,
      getTickets,
      getEvents,
      formatDate
    };
  },
  components: {
    EventList,
    TicketList,
    EventModal,
    UpdateEventModal,
    TicketModal,
    LoginModal,
  }
});
</script>

<style lang="scss">
html, body {
  padding:0px !important;
  margin: 0px !important;
  width:100%;
  height:100%;
  max-height: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: black;
}
#app {
  width: 100%;
  height: 100%;
  max-height: 100%;
}
#wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: white;
}
#event-list {
  overflow-y: auto;
}
#ticket-list {
  overflow-y: auto;
}
</style>
