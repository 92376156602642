<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">

                <!-- Header of the modal -->
                <div class="modal-header">
                    <slot name="header">
                        <h3>Ticket erstellen</h3>
                        {{ event.name }} - {{ event.city }} - {{ new Date(event.date).toLocaleDateString("de-DE") }}
                        <div v-if="error" class="alert alert-danger" role="alert">
                            {{ error }}
                        </div>
                    </slot>
                </div>
                <div class="modal-body">   
                    <div class="row">
                        <div class="col-12">
                            <slot name="body">
                                <div class="form-group row">
                                    <div class="col-3">
                                        <label for="order">Bestellung</label>
                                        <input class="form-control mb-3" name="order" v-model="order" @keypress="isNumber($event)"/>
                                    </div>
                                    <div class="col-3">
                                        <label for="order">Anzahl der Tickets</label>
                                        <input class="form-control mb-3" name="amount" v-model="amount" @keypress="isNumber($event)"/>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button class="btn btn-outline-danger" @click="$emit('TicketModalClosed')">Abbrechen</button>
                        <button class="btn btn-success" @click="addTicket(false)" :disabled="!valid">Speichern</button>
                        <button class="btn btn-primary" @click="addTicket(true)" :disabled="!valid">Speichern & PDF erstellen</button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "TicketModal",
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            category: this.event.pricecategories[0].id,
            type: 2,
            order: null,
            error: "",
            amount: 1,
        }
    },
    methods: {
        addTicket (p) {
            this.error = "";
            axios.post(process.env.VUE_APP_BACKEND + '/api/tickets', {
                eventid: this.event.id,
                pricecategoryid: this.category,
                type: parseInt(this.type),
                order: parseInt(this.order),
                amount: parseInt(this.amount),
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                console.log(response.data)
                if (p) {
                    if (this.amount > 1) {
                        var url = process.env.VUE_APP_BACKEND + '/api/orders/' + this.event.id + '/' + this.order + '/print'
                        window.open(url, '_self').focus()
                    } else {

                        var url = process.env.VUE_APP_BACKEND + '/api/tickets/' + response.data[0].id + '/print'
                        window.open(url, '_self').focus()
                    }
                }
                this.$emit('TicketModalSaved') 
            }).catch((e) => {
                console.log(e)
                this.error = e.response.data.message
            })         
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
    },
    computed: {
        valid() {
            if (this.category && this.type == 2 && this.order) {
                return true
            } else {
                return false
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

input[type=checkbox], input[type=radio] {
    width: 2.5rem;
    height: 2.5rem;
}

</style>